import React, { FC, useEffect, useState } from "react"
import Bounce from "react-reveal/Bounce"
import { ComboType } from "../util/combo"
import { folders } from "../util/ThemeAndCards"

interface ScoreProperties {
  score: number
  currentThemeName?: string
}

const Score: FC<ScoreProperties> = ({ score, currentThemeName }) => {
  const [internalScore, setInternalScore] = useState<number>(0)
  const [combo, setCombo] = useState<ComboType | boolean>(false)
  const [comboTimeout, setComboTimeout] = useState<NodeJS.Timeout>()
  const [currentFolder, setCurrentFolder] = useState<string>("")

  useEffect(() => setInternalScore(score), [score])

  useEffect(() => {
    if (combo === false) return
    if (comboTimeout) clearTimeout(comboTimeout)
    setComboTimeout(setTimeout(() => setCombo(false), 2500))
  }, [combo])

  useEffect(() => {
    if (score - internalScore === 10) setCombo(ComboType.SINGLE)
    if (score - internalScore === 15) setCombo(ComboType.DOUBLE)
  }, [score])

  useEffect(() => {
    const folder = folders.find(f => f === currentThemeName)
    if (folder) setCurrentFolder(folder)
  }, [])

  const getComboPath = (is2x = false) => {
    return `/image/svg/combo/${currentThemeName}/${is2x ? "2" : "1"}.svg`
  }

  return (
    <div className="score">
      <div className="current">
        <p>jouw score</p>
        <h1>{("000" + score).substr(-3)}</h1>
      </div>

      {combo === ComboType.SINGLE && (
        <Bounce duration={500}>
          <div className="combo1">
            <img
              src={getComboPath()}
              alt="Combo x1"
              width={"249px"}
              height={"72px"}
            />
          </div>
        </Bounce>
      )}

      {combo === ComboType.DOUBLE && (
        <Bounce duration={500}>
          <div className="combo2">
            <img
              src={getComboPath(true)}
              alt="Combo x2"
              width={"280.5px"}
              height={"76.5px"}
            />
          </div>
        </Bounce>
      )}
    </div>
  )
}

export default Score
