import * as React from "react"
import { useEffect, useState, useContext } from "react"
import { FacebookShareButton } from "react-share"
import { useFormik } from "formik"
import * as Yup from "yup"
import classNames from "classnames"
import { httpClient } from "../util/axios"
import { AxiosResponse } from "axios"
import { GameContext, GameContextPage } from "../game/GameContext"
import { onGameFinish } from "../util/ThemeAndCards"

interface FormInterface {
  name?: string
  email?: string
  termsOfService?: number
}

interface HighScoreInterface {
  name: string
  score: number
}

const SubmitPage: React.FC = () => {
  const { setPageId } = useContext(GameContext)
  const [token, setToken] = useState<string>()
  const [score, setScore] = useState<number>()
  const [submitted, setSubmitted] = useState<boolean>()
  const [error, setError] = useState<boolean>()
  const [highScores, setHighScores] = useState<HighScoreInterface[]>()
  const quote = `Versla mijn score van ${score} punten! Scoor in 60 seconden zoveel mogelijk punten en maak kans op prijzen van Eurom!`
  const hashtag = "#playenwin"
  const url = "https://memory-eurom.nl/"

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Voor-/achternaam is te kort")
      .max(100, "Voor-/achternaam is te lang")
      .required("Voor-/achternaam is verplicht"),
    email: Yup.string()
      .email("E-mailadres is ongeldig")
      .required("E-mailadres is verplicht"),
    termsOfService: Yup.boolean()
      .required("U dient akkoord te gaan met de voorwaarden")
      .oneOf([true], "U dient akkoord te gaan met de voorwaarden"),
  })

  const form = useFormik<FormInterface>({
    initialValues: {
      name: "",
      email: "",
    },
    validateOnChange: false, // TODO: fix on change after submit?
    validateOnBlur: false,
    validationSchema,
    onSubmit: values => {
      httpClient
        .post("/contact", {
          token,
          ...values,
        })
        .then(() => {
          window?.sessionStorage?.clear()
          setSubmitted(true)
          fetchHighScore()
        })
        .catch(() => {
          setError(true)
        })
    },
  })

  const fetchHighScore = () => {
    httpClient
      .get(`/high-scores`)
      .then((result: AxiosResponse<HighScoreInterface[]>) => {
        setHighScores(result.data)
      })
  }

  useEffect(() => {
    const token = window?.sessionStorage?.getItem("token")
    if (!token) {
      setPageId(GameContextPage.Landing)
      return
    }
    setToken(token)

    httpClient
      .get(`/?token=${token}`)
      .then((result: AxiosResponse<{ score: number }>) => {
        setScore(result.data.score)
      })

    fetchHighScore()
    onGameFinish()
  }, [])

  return (
    <div className="submit">
      <div style={{}} className="header3">
        <img
          height={"45px"}
          width={"180px"}
          alt={"logo"}
          src="/image/svg/EuromJubileumLogo.svg"
        />
      </div>

      <img src={"/image/svg/ScoorEnWin.svg"} width={"90%"} height={"auto"} />

      {!isNaN(score as number) ? (
        <div className="score">
          <div className="current">
            <div className="jouwScore">
              <h2>jouw</h2>
              <h2>score</h2>
            </div>
            <h1>{("000" + score).substr(-3)}</h1>
          </div>
        </div>
      ) : null}

      <div className="share">
        <p>Deel mijn score op</p>
        <div className={"shareIcons"}>
          <div>
            <FacebookShareButton url={url} quote={quote} hashtag={hashtag}>
              <img
                width={"48px"}
                height={"48px"}
                src="/image/svg/facebook.svg"
              />
            </FacebookShareButton>
          </div>
        </div>
      </div>

      {highScores ? (
        <div className="highscores">
          <h1>high scores</h1>
          {highScores.map((highScore, index) => (
            <div key={index} className="highscore">
              <div className="left">
                <p className="position">{index + 1}.</p>
                <p className="name">{highScore.name}</p>
              </div>
              <div className="right">
                <p>{("000" + highScore.score).substr(-3)}</p>
              </div>
            </div>
          ))}
        </div>
      ) : null}

      <a
        className="replay"
        onClick={() => setPageId(GameContextPage.Instructions)}
      >
        <img width={"100%"} height={"auto"} src={"/image/svg/NogEenKeer.svg"} />
      </a>

      {submitted ? (
        <div className="instructions">
          <h1 style={{ fontSize: "1.5rem" }}>Deelname opgeslagen</h1>
          <p>
            Gefeliciteerd! Uw deelname is opgeslagen. De winnaar wordt
            binnenkort bekend gemaakt.
          </p>
        </div>
      ) : (
        <div className="instructions">
          <h1 className={"gegevensAchterlaten"}>Laat je gegevens achter</h1>
          <h1 className={"gegevensAchterlaten"}>
            en maak kans op mooie prijzen
          </h1>
        </div>
      )}

      {error ? (
        <div className="instructions">
          <h1>Oeps, er ging iets fout</h1>
          <p>
            Er ging iets fout bij het opslaan van uw deelname. Controleer uw
            verbinding en probeer het opnieuw.
          </p>
        </div>
      ) : null}

      {!submitted ? (
        <form className="form" onSubmit={form.handleSubmit}>
          <input
            type="text"
            placeholder="Naam"
            name="name"
            onChange={form.handleChange}
            value={form.values.name}
            className={classNames(form.errors.name ? "error" : null)}
          />
          {form.errors.name ? <span>{form.errors.name}</span> : null}

          <input
            type="email"
            placeholder="Email"
            name="email"
            onChange={form.handleChange}
            value={form.values.email}
            className={classNames(form.errors.email ? "error" : null)}
          />
          {form.errors.email ? <span>{form.errors.email}</span> : null}

          <div className="consent">
            <div
              className={classNames(
                "consent-container",
                form.errors.termsOfService ? "error" : null
              )}
            >
              <input
                className={"checkbox"}
                type="checkbox"
                onChange={form.handleChange}
                name="termsOfService"
                value={form.values.termsOfService}
              />

              <a onClick={() => setPageId(GameContextPage.Conditions)}>
                Ik ga akkoord met de actievoorwaarden
              </a>
            </div>

            {form.errors.termsOfService ? (
              <span>{form.errors.termsOfService}</span>
            ) : null}
          </div>

          <button type="submit" className="submit">
            <img
              width={"100%"}
              height={"auto"}
              src={"/image/svg/Deelnemen.svg"}
            />
          </button>
        </form>
      ) : null}
    </div>
  )
}

export default SubmitPage
