import * as React from "react"
import { useContext, useEffect, useState } from "react"
import Bounce from "react-reveal/Bounce"
import Zoom from "react-reveal/Zoom"
import Fade from "react-reveal/Fade"
import { GameContext, GameContextPage } from "../game/GameContext"

enum IndexEnum {
  RIGHT,
  MISTAKE,
  COMBO,
}

const IndexContent = {
  [IndexEnum.RIGHT]: {
    title: "+5 punten",
    content: "Voor elke goede set",
  },
  [IndexEnum.MISTAKE]: {
    title: "-1 punt",
    content: "Voor elke foute set",
  },
  [IndexEnum.COMBO]: {
    title: "Scoor combo's",
    content: "Door meerdere goede sets na elkaar",
  },
}

const InstructionsPage: React.FC = () => {
  const { setPageId } = useContext(GameContext)
  const [index, setIndex] = useState<IndexEnum>(IndexEnum.RIGHT)

  const renderAnimation = (children: any) => {
    const duration = 500
    if (index === IndexEnum.RIGHT)
      return <Bounce duration={duration}>{children}</Bounce>
    if (index === IndexEnum.MISTAKE)
      return <Zoom duration={duration}>{children}</Zoom>
    return <Fade duration={duration}>{children}</Fade>
  }

  useEffect(() => {
    setTimeout(() => {
      if (index === IndexEnum.COMBO) return setPageId(GameContextPage.Game)
      setIndex(index + 1)
    }, 1500)
  }, [index])

  return (
    <div className="instructions">
      {renderAnimation(
        <>
          <h1>{3 - (index as number)}</h1>
          <h2>{IndexContent[index].title}</h2>
          <p>{IndexContent[index].content}</p>
        </>
      )}
    </div>
  )
}

export default InstructionsPage
