import * as React from "react"
import { useContext } from "react"
import { GameContext, GameContextPage } from "../game/GameContext"

const ConditionsPage: React.FC = () => {
  const { setPageId } = useContext(GameContext)

  const conditions = [
    "De actieperiode van dit spel is van 01-11-24 tot 30-11-24",
    "De deelnemer die de hoogste score heeft na deze periode wint een EUROM Double Fred koel/vries box",
    "Deze verzenden wij naar de winnaar",
    "Bij gelijke hoogste scores krijgt iedere winnaar een EUROM Double Fred koel/vries box",
    "Voor het spelen van dit memory spel en het kans maken op prijzen zijn geen kosten verbonden",
    "Iedereen mag meedoen!",
    "Deelnemers mogen zo vaak meespelen als ze willen",
    "Eurom neemt zelf contact op met de prijswinnaar.",
  ]

  return (
    <div className="conditions">
      <div style={{}} className="header3">
        <img
          height={"45px"}
          width={"180px"}
          alt={"logo"}
          src="/image/svg/EuromJubileumLogo.svg"
        />
      </div>
      <img src={"/image/svg/ScoorEnWin.svg"} width={"90%"} height={"auto"} />

      <h1>Actie voorwaarden</h1>

      <h2>
        Hierbij de algemene voorwaarden die bij het Eurom memoryspel mogen
        komen:
      </h2>

      <ol>
        {conditions.map((condition, key) => (
          <li key={key}>
            <p>{condition}</p>
          </li>
        ))}
      </ol>

      <a
        style={{ cursor: "pointer" }}
        onClick={() => setPageId(GameContextPage.Submit)}
      >
        Terug naar de game
      </a>
    </div>
  )
}

export default ConditionsPage
