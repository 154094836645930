import React, { FC, useEffect, useState } from "react"

interface TimerProperties {
  initialTime: number
  remainingTime: number
}

const Timer: FC<TimerProperties> = ({ initialTime, remainingTime }) => {
  const [percentage, setPercentage] = useState<number>(100)

  useEffect(() => {
    if (remainingTime <= 0) return
    setPercentage((100 / initialTime) * remainingTime) // TODO: improve penalty
  }, [remainingTime])

  return (
    <div className="timer">
      <div
        className="animation"
        style={{
          width: `${percentage}%`,
        }}
      >
        <img alt={"TimerIcon"} src={"/image/svg/TimerIcon.svg"} />
      </div>
    </div>
  )
}

export default Timer
