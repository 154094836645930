export const folders = [
  "Dblauw",
  "Lblauw",
  "Dgroen",
  "Lgroen",
  "geel",
  "oranje",
  "paars",
] as const

type Folder = typeof folders[number]

export const colorMappings: Record<
  Folder,
  {
    borderColor: string
    backgroundColor: string
    animationColor: string
    combo1: string
    combo2: string
  }
> = {
  Dblauw: {
    borderColor: "#6396b1",
    backgroundColor: "#6396b1",
    animationColor: "#4b5d6e",
    combo1: "/image/svg/combo/Dblauw/1.svg",
    combo2: "/image/svg/combo/Dblauw/2.svg",
  },
  Lblauw: {
    borderColor: "#85c9f0",
    backgroundColor: "#85c9f0",
    animationColor: "#6396b1",
    combo1: "/image/svg/combo/Lblauw/1.svg",
    combo2: "/image/svg/combo/Lblauw/2.svg",
  },
  geel: {
    borderColor: "#edd156",
    backgroundColor: "#edd156",
    animationColor: "#daab4e",
    combo1: "/image/svg/combo/geel/1.svg",
    combo2: "/image/svg/combo/geel/2.svg",
  },
  paars: {
    borderColor: "#c188ae",
    backgroundColor: "#c188ae",
    animationColor: "#a17090",
    combo1: "/image/svg/combo/paars/1.svg",
    combo2: "/image/svg/combo/paars/2.svg",
  },
  oranje: {
    borderColor: "#ee7656",
    backgroundColor: "#ee7656",
    animationColor: "#ae583f",
    combo1: "/image/svg/combo/oranje/1.svg",
    combo2: "/image/svg/combo/oranje/2.svg",
  },
  Dgroen: {
    borderColor: "#58b999",
    backgroundColor: "#58b999",
    animationColor: "#40876f",
    combo1: "/image/svg/combo/Dgroen/1.svg",
    combo2: "/image/svg/combo/Dgroen/2.svg",
  },
  Lgroen: {
    borderColor: "#b8d584",
    backgroundColor: "#b8d584",
    animationColor: "#96ae6d",
    combo1: "/image/svg/combo/Lgroen/1.svg",
    combo2: "/image/svg/combo/Lgroen/2.svg",
  },
}

const folderCardCounts: Record<Folder, number> = {
  Dblauw: 7,
  Lblauw: 6,
  geel: 7,
  paars: 5,
  oranje: 6,
  Dgroen: 7,
  Lgroen: 6,
}

export function generateDeck() {
  const selectedFolder = folders[Math.floor(Math.random() * folders.length)]
  const cardCount = folderCardCounts[selectedFolder]
  const cardsInFolder = Array.from({ length: cardCount }, (_, i) => i + 1)
  const deck = cardsInFolder.sort(() => 0.5 - Math.random()).slice(0, 3)
  const finalDeck = deck
    .concat([...deck])
    .map(value => ({ folder: selectedFolder, value }))
    .sort(() => 0.5 - Math.random())

  applyTheme(selectedFolder)

  return finalDeck
}

function applyTheme(folder: Folder | "default") {
  if (folder === "default") {
    document.body.style.backgroundColor = "#ae9a64"
    document.documentElement.style.setProperty("--border-color", "#ae9a64")
    document.documentElement.style.setProperty("--background-color", "#ae9a64")
    document.documentElement.style.setProperty(
      "--animation-bg-color",
      "#ae9a64"
    )
    document.documentElement.style.setProperty("--combo1", "")
    document.documentElement.style.setProperty("--combo2", "")
  } else {
    const {
      borderColor,
      backgroundColor,
      animationColor,
      combo1,
      combo2,
    } = colorMappings[folder]
    document.body.style.backgroundColor = borderColor
    document.documentElement.style.setProperty("--border-color", borderColor)
    document.documentElement.style.setProperty(
      "--background-color",
      backgroundColor
    )
    document.documentElement.style.setProperty(
      "--animation-bg-color",
      animationColor
    )
    document.documentElement.style.setProperty("--combo1", `url(${combo1})`)
    document.documentElement.style.setProperty("--combo2", `url(${combo2})`)
  }
}

export function onGameFinish() {
  applyTheme("default")
}
