import * as React from "react"
import Zoom from "react-reveal/Zoom"
import Fade from "react-reveal/Fade"
import { GameContext, GameContextPage } from "../game/GameContext"
import { useContext } from "react"

const InfoPage: React.FC = () => {
  const { setPageId } = useContext(GameContext)

  return (
    <div className="info">
      <div className="header">
        <a onClick={() => setPageId(GameContextPage.Landing)}>
          <img
            width={"200px"}
            height={"200px"}
            alt={"logo"}
            src="/image/svg/logo-2.svg"
          />
        </a>
      </div>

      <Zoom bottom duration={1000}>
        <div>
          <div>
            <a onClick={() => setPageId(GameContextPage.Instructions)}>
              <h1>Perfect Match</h1>
            </a>
          </div>
          <div className="text">
            <p>
              Scoor in 60 seconden zoveel mogelijk punten en maak kans op een
              double fred.
            </p>
          </div>
        </div>
      </Zoom>

      <Fade bottom duration={500} delay={500}>
        <a
          onClick={() => setPageId(GameContextPage.Instructions)}
          className="play-button"
        >
          <img
            alt={"start"}
            style={{ cursor: "pointer" }}
            width={"450px"}
            height={"160px"}
            src={"/image/svg/start.svg"}
          />
        </a>
      </Fade>
      <div className="footer">
        <img
          height={"45px"}
          width={"180px"}
          alt={"logo"}
          src="/image/svg/EuromJubileumLogo.svg"
        />
      </div>
    </div>
  )
}

export default InfoPage
