import * as React from "react"
import { useContext } from "react"
import classNames from "classnames"
import { GameContext, GameContextPage } from "../game/GameContext"

const Layout: React.FC = ({ children }) => {
  const { pageId, setPageId } = useContext(GameContext)

  return (
    <body>
      <main
        className={classNames(
          pageId === GameContextPage.Instructions ? "gradient" : null
        )}
      >
        {pageId !== 2 && <a></a>}
        {children}
      </main>
    </body>
  )
}

export default Layout
