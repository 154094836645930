// eurom/src/components/card.tsx
import React, { FC } from "react"
import classNames from "classnames"

export interface CardInterface {
  onClick: Function
  selected?: boolean
  flipping?: boolean
  matched?: boolean
  value: { folder: string; value: number }
}

const Card: FC<CardInterface> = ({
  onClick,
  selected,
  value,
  flipping,
  matched,
}) => {
  const canClick = !selected && !matched && !flipping

  const click = () => {
    if (canClick) onClick()
  }

  return (
    <div
      className={classNames({
        card: true,
        selected,
        flipping,
        matched,
      })}
      onClick={click}
    >
      <div className="back"></div>

      <div className="front">
        <img
          src={`/image/svg/cards/${value.folder}/${value.value}.jpg`}
          alt={value.value.toString()}
        />
      </div>
    </div>
  )
}

export default Card
// console.log(`/image/svg/cards/Dblauw/${value}.jpg`)
