import axios from "axios"

const getHostName = () =>
  typeof window !== `undefined` ? location.hostname : "localhost"

// TODO: fix reversed behavior (e.g. on Safari mobile?)
export const httpClient = axios.create({
  baseURL:
    getHostName() === "localhost"
      ? "http://localhost:3020"
      : "https://eurom-api.playscore.nl/",
  timeout: 2500,
})
