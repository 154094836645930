import React, { useEffect, useMemo, useState } from "react"
import Card from "../components/card"
import { generateDeck } from "../util/ThemeAndCards"

const useGame = (time: number) => {
  const [matches, setMatch] = useState<any>([])
  const [flipping, setFlip] = useState<any>({ i: [], v: [] })
  const [deck, setDeck] = useState(() => generateDeck()) // Ensure generateDeck is called only once
  const [timeRemaining, setTimeRemaining] = useState<number>(time)
  const [streak, setStreak] = useState<number>(0)
  const [score, setScore] = useState<number>(0)

  //@ts-ignore
  const currentThemeName = useMemo(() => deck?.at(0)?.folder, [deck])

  const updateScore = (score: number) => {
    if (score <= 0) return
    setScore(score)
  }

  // Check if current view is matched
  const completed = matches.length === deck.length / 2

  // Automatic reshuffle
  useEffect(() => {
    if (!completed) return

    // Reset streak
    setStreak(0)

    // Reshuffle cards
    setTimeout(() => {
      setFlip({ i: [], v: [] })
      setMatch([])
      setDeck(generateDeck())
    }, 500)
  }, [completed])

  useEffect(() => {
    if (flipping.i.length !== 2) return

    if (flipping.v[0].value === flipping.v[1].value) {
      setMatch([...matches, flipping.v[0].value])
      setFlip({ i: [], v: [] })

      const currentStreak = streak + 1

      updateScore(score + currentStreak * 5)

      setStreak(currentStreak)
    } else {
      updateScore(score - 1)

      setStreak(0)

      setTimeout(() => {
        setFlip({ i: [], v: [] })
      }, 500)
    }
  }, [flipping])

  useEffect(() => {
    if (timeRemaining === 0) return
    setTimeout(() => setTimeRemaining(timeRemaining - 1), 1000)
  }, [timeRemaining])

  const onClick = (value: any, index: any) => {
    const { i, v } = flipping
    if (i.length < 2) {
      setFlip({ i: [...i, index], v: [...v, value] })
    }
  }

  const cards = deck.map((value, i) => (
    <Card
      key={i}
      onClick={() => onClick(value, i)}
      value={value}
      matched={matches.includes(value.value)}
      flipping={flipping.i.includes(i)}
    />
  ))

  return [cards, timeRemaining, score, currentThemeName]
}

export default useGame
